/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Service to manage the storage of the user's data
 * using the ionic-storage library.
 *
 * @author Alejandro Mejía
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage: Storage | null = null;
  private subject = new Subject<Storage>();

  get storage$(): Observable<Storage> {
    return this.subject.asObservable().pipe(filter((storage) => !!storage));
  }
  constructor(storage: Storage) {
    this.initStorage(storage);
  }

  /**
   * Initialize the storage service.
   *
   * @param { Storage } storage - Storage service to create a new storage instance
   */
  private async initStorage(storage: Storage): Promise<void> {
    this.storage = await storage.create();
    this.subject.next(this.storage);
    this.subject.complete();
  }

  /**
   * Store the value for the given key.
   *
   * @param { string } key - Key to store the value under
   * @param { T } value - Value to store
   */
  async setItem<T>(key: string, value: T): Promise<void> {
    await this.storage?.set(key, value);
  }

  /**
   * Retrieve the value for the given key.
   *
   * @param { string } key - Key to retrieve the value for
   * @returns Returns a promise with the value of the given key
   */
  async getItem<T>(key: string): Promise<T | null> {
    if (!this.storage) {
      return this.throwIfNotInitialized();
    }
    return await this.storage.get(key);
  }

  /**
   * Remove any value associated with this key.
   *
   * @param key - Key to remove
   * @returns Returns a promise that resolves when the value is removed
   */
  async removeItem(key: string): Promise<void> {
    if (!this.storage) {
      return this.throwIfNotInitialized();
    }
    return await this.storage.remove(key);
  }

  /**
   * Clear the entire key value store. WARNING: HOT!
   *
   * @returns Returns a promise that resolves when the store is cleared
   */
  async clear(): Promise<void> {
    if (!this.storage) {
      return this.throwIfNotInitialized();
    }
    await this.storage.clear();
  }

  /**
   * Retrieve the number of keys stored.
   *
   * @returns Returns a promise that resolves with the number of keys stored.
   */
  async keysLength(): Promise<number> {
    if (!this.storage) {
      return this.throwIfNotInitialized();
    }
    return await this.storage.length();
  }

  /**
   * Retrieve the keys stored.
   *
   * @returns Returns a promise that resolves with the keys in the store.
   */
  async keys(): Promise<string[]> {
    if (!this.storage) {
      return this.throwIfNotInitialized();
    }
    return await this.storage.keys();
  }

  /**
   * Iterate through each (key, value) pair.
   *
   * @param iteratorCallback - A callback of the form (value, key, iterationNumber)
   * @returns Returns a promise that resolves when the iteration has finished.
   */
  async forEach(
    iteratorCallback: (
      value: unknown,
      key: string,
      iterationNumber: Number
    ) => unknown
  ): Promise<void> {
    if (!this.storage) {
      return this.throwIfNotInitialized();
    }
    return await this.storage.forEach(iteratorCallback);
  }

  /**
   * Throw an error if the storage service has not been initialized.
   *
   * @returns A error if the storage service is not initialized
   */
  async throwIfNotInitialized(): Promise<never> {
    return Promise.reject(new Error('Storage is not initialized'));
  }
}
