import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { NavController } from '@ionic/angular';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';

import { ToastService } from 'src/app/services/toast.service';
import { environment } from '@environments/environment';

import { AuthService } from '@auth/services/auth.service';
import { AuthPaths } from '@auth/enums/auth-paths.enum';
import { TokenService } from '@auth/services/token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
    private toastService: ToastService,
    private navController: NavController,
    private translateService: TranslateService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!request.url.includes(environment.baseApiUrl)) {
      return next.handle(request);
    }
    const accessToken = this.tokenService.accessToken;
    if (accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    return next.handle(request).pipe(
      catchError((error) => {
        if (!this.authService.isAuthenticated && error.status === 401) {
          this.toastService.showErrorToast(
            this.translateService.instant('auth.errors.sessionExpired')
          );
          this.navController.navigateForward(
            [AuthPaths.BASE, AuthPaths.LOGOUT],
            {
              queryParams: {
                continueUrl: location.pathname,
              },
            }
          );
        }
        return throwError(error);
      })
    );
  }
}
