// This file was autogenerated by dynamically running set-env.config.ts and using dotenv for managing API key secrecy
export const environment = {
  production: true,
  environment: 'development',
  baseApiUrl: 'https://hirit-api-dev.herokuapp.com/api/v1',
  firebaseConfig: {
    apiKey: 'AIzaSyAjgy6710hGXTI1uwe9Ne2LSUcEOOOnwQI',
    projectId: 'cars-app-test',
    appId: '1:574477764312:web:fe5532db06c515d7e3f9ad',
    storageBucket: 'cars-app-test.appspot.com',
    authDomain: 'cars-app-test.firebaseapp.com',
    messagingSenderId: '574477764312',
    measurementId: 'G-1GFT8YG001',
  },
  googleMaps: {
    apiKey: 'AIzaSyBfgqF_b-1qcI_0_OoEOr6bpjG7tCDYgTk',
  },
  storage: {
    name: 'dev-hirit-db',
    storeName: 'dev-hirit-store',
  },
  ipInfoToken: '274af153f7e07a',
} as const;
