import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConfirmationResult,
  getAuth,
  RecaptchaVerifier,
  signInWithCustomToken,
  signInWithPhoneNumber,
  User,
  UserCredential,
} from 'firebase/auth';

import { environment } from '@environments/environment';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { TokenService } from './token.service';
import { Token } from '@auth/models/token.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  windowRef = window as any;
  protected baseApiUrl = `${environment.baseApiUrl}`;
  private authService = getAuth();

  private userSubject = new BehaviorSubject<User | null>(this.authService.currentUser);
  get user$(): Observable<User | null> {
    return this.userSubject.asObservable();
  }

  get user(): User | null {
    return this.userSubject.value;
  }

  get isAuthenticated(): boolean {
    return !!this.tokenService.accessToken || !!this.authService.currentUser;
  }

  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {
    this.authService.onAuthStateChanged((user) => {
      this.userSubject.next(user);
    });
  }

  checkIfPhoneExists(phoneNumber: string): Observable<unknown> {
    phoneNumber = encodeURIComponent(phoneNumber);
    return this.httpClient.get(
      `${this.baseApiUrl}/users/exist?phoneNumber=${phoneNumber}`
    );
  }

  loginWithEmailAndPassword(
    email: string,
    password: string
  ): Observable<UserCredential> {
    return this.httpClient
      .post<{ accessToken: string }>(`${this.baseApiUrl}/auth/login`, {
        email,
        password,
      })
      .pipe(
        switchMap((token: Token) => {
          return this.signInWithCustomToken(token);
        })
      );
  }

  sendVerificationCode(phoneNumber: string): Observable<unknown> {
    const auth = this.authService;
    const windowRef = window as any;
    return new Observable((observer) => {
      signInWithPhoneNumber(auth, phoneNumber, windowRef.recaptchaVerifier)
        .then((confirmationResult) => {
          windowRef.confirmationResult = confirmationResult;
          observer.next(confirmationResult);
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  verifyCode(code: string): Observable<UserCredential> {
    const windowRef = window as any;
    return from(
      (windowRef.confirmationResult as ConfirmationResult).confirm(code)
    ).pipe(switchMap(() => this.generateLocalToken()));
  }

  generateLocalToken(): Observable<UserCredential> {
    return from(this.user!.getIdToken()).pipe(
      take(1),
      switchMap((token) => {
        return this.httpClient.post<{ accessToken: string }>(
          `${this.baseApiUrl}/auth/generate-token`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }),
      switchMap((token: Token) => {
        return this.signInWithCustomToken(token);
      })
    );
  }

  private signInWithCustomToken(token: Token): Observable<UserCredential> {
    this.tokenService.token = token;
    const { accessToken } = token;
    return from(signInWithCustomToken(this.authService, accessToken));
  }

  insertRecaptcha(
    elementId: string | HTMLElement,
    callback: () => void,
    expiredCallback: () => void,
    size: 'invisible' | 'compact' | 'normal' = 'normal'
  ) {
    this.windowRef.recaptchaVerifier = new RecaptchaVerifier(
      elementId,
      {
        size,
        callback: () => {
          callback();
        },
        'expired-callback': () => {
          expiredCallback();
        },
      },
      this.authService
    );
  }

  renderRecaptcha(callback: () => void): void {
    this.windowRef.recaptchaVerifier.render().then((widgetId: number) => {
      this.windowRef.recaptchaWidgetId = widgetId;
      callback();
    });
  }

  resetRecaptcha(): void {
    this.windowRef.grecaptcha.reset(this.windowRef.recaptchaWidgetId);
  }

  async logout(): Promise<void> {
    await this.authService.signOut();
  }
}
