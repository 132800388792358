import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@auth/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@menu/menu.module').then((m) => m.MenuPageModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'car/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('@car/car.module').then((m) => m.CarPageModule),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'owner/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@owner/owner.module').then((m) => m.OwnerPageModule),
  },
  {
    path: 'address',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./address/address.module').then((m) => m.AddressPageModule),
  },
  {
    path: 'delivery-points',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./delivery-points/delivery-points.module').then(
        (m) => m.DeliveryPointsPageModule
      ),
  },
  {
    path: 'specs',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./specs/specs.module').then((m) => m.SpecsPageModule),
  },
  {
    path: 'spec-selection',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./spec-selection/spec-selection.module').then(
        (m) => m.SpecSelectionPageModule
      ),
  },
  {
    path: 'rental',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@rental/rental.module').then((m) => m.RentalPageModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'payment-methods',
    loadChildren: () =>
      import('@payment/payment.module').then((m) => m.PaymentPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
