import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { addIcons } from 'ionicons';

import { StorageService } from './services/storage.service';

@Component({
  selector: 'hirit-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(translate: TranslateService, storageService: StorageService) {
    translate.use('es');
    this.addCustomIcons();
  }

  ngOnInit(): void {}

  addCustomIcons(): void {
    addIcons({
      apple: 'assets/icons/apple.icon.svg',
      bell: 'assets/icons/bell.icon.svg',
      bluetooth: 'assets/icons/bluetooth.icon.svg',
      calendar: 'assets/icons/calendar.icon.svg',
      check: 'assets/icons/check.icon.svg',
      edit: 'assets/icons/edit.icon.svg',
      eye: 'assets/icons/eye.icon.svg',
      facebook: 'assets/icons/facebook.icon.svg',
      google: 'assets/icons/google.icon.svg',
      info: 'assets/icons/info.icon.svg',
      joystick: 'assets/icons/joystick.icon.svg',
      key: 'assets/icons/key.icon.svg',
      location: 'assets/icons/location.icon.svg',
      mastercard: 'assets/icons/mastercard.icon.svg',
      navigate: 'assets/icons/navigate.icon.svg',
      playlist: 'assets/icons/playlist.icon.svg',
      radio: 'assets/icons/radio.icon.svg',
      scan: 'assets/icons/scan.icon.svg',
      suitcase: 'assets/icons/suitcase.icon.svg',
      tachometer: 'assets/icons/tachometer.icon.svg',
      trash: 'assets/icons/trash.icon.svg',
      user: 'assets/icons/user.icon.svg',
      users: 'assets/icons/users.icon.svg',
      visa: 'assets/icons/visa.icon.svg',
      webcam: 'assets/icons/webcam.icon.svg',
      wind: 'assets/icons/wind.icon.svg',
      'american-express': 'assets/icons/american-express.icon.svg',
      'baby-seat': 'assets/icons/baby-seat.icon.svg',
      'bank-card': 'assets/icons/bank-card.icon.svg',
      'check-circle': 'assets/icons/check-circle.icon.svg',
      'chevron-down': 'assets/icons/chevron-down.icon.svg',
      'eye-off': 'assets/icons/eye-off.icon.svg',
      'id-card': 'assets/icons/id-card.icon.svg',
      'map-marker': 'assets/icons/map-marker.icon.svg',
    });
  }
}
