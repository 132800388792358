import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeEsSV from '@angular/common/locales/es-SV';

import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ErrorTailorModule } from '@ngneat/error-tailor';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

import {
  provideAnalytics,
  getAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

import { AuthInterceptor } from '@auth/interceptors/auth.interceptor';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { ControlErrorComponent } from './components/control-error/control-error.component';
import { AuthService } from '@auth/services/auth.service';
import { TokenService } from '@auth/services/token.service';

registerLocaleData(localeEsSV);

@NgModule({
  declarations: [AppComponent, ControlErrorComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
      backButtonIcon: 'chevron-back-outline',
    }),
    IonicStorageModule.forRoot({
      name: environment.storage.name,
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
      storeName: environment.storage.storeName,
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient],
      },
    }),
    ErrorTailorModule.forRoot({
      controlErrorComponent: ControlErrorComponent,
      errors: {
        useFactory: (translateService: TranslateService) => {
          return {
            required: () => translateService.instant('errors.required'),
            minlength: ({ requiredLength }) =>
              translateService.instant('errors.minlength', {
                requiredLength,
              }),
            maxlength: ({ requiredLength }) =>
              translateService.instant('errors.maxlength', {
                requiredLength,
              }),
            email: () => translateService.instant('errors.email'),
            min: ({ min }) => translateService.instant('errors.min', { min }),
            max: ({ max }) => translateService.instant('errors.max', { max }),
            pattern: () => translateService.instant('errors.pattern'),
          };
        },
        deps: [TranslateService],
      },
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideMessaging(() => getMessaging()),
    provideStorage(() => getStorage()),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [AuthService, TokenService, HttpClient],
    },
    ScreenTrackingService,
    UserTrackingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
