import { Injectable } from '@angular/core';
import { Token } from '@auth/models/token.model';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  protected baseApiUrl = `${environment.baseApiUrl}`;

  private subject: BehaviorSubject<Token | null>;

  get accessToken$(): Observable<string | null> {
    return of(this.accessToken);
  }

  get accessToken(): string | null {
    const { accessToken } = this.subject.value || { accessToken: null };
    return accessToken;
  }

  set token(token: Token) {
    this.subject.next(token);
    this.storageService.setItem<Token>('token', token);
  }

  constructor(private storageService: StorageService) {
    this.init();
    this.subject = new BehaviorSubject<Token | null>(null);
  }

  private async init(): Promise<void> {
    this.storageService.storage$
      .pipe(
        filter((storage) => !!storage),
        take(1)
      )
      .subscribe({
        next: async () => {
          const token = await this.storageService.getItem<Token>('token');
          this.subject.next(token);
        },
      });
  }
}
