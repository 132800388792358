import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';
import { IonColor } from '../utils/types/ion-color.type';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  async showToastWithOptions(
    options: ToastOptions
  ): Promise<HTMLIonToastElement> {
    const toast = await this.toastController.create({
      ...options,
      mode: 'ios',
      keyboardClose: true,
    });
    toast.present();
    return toast;
  }

  async showToast(
    message: string,
    duration = 2000,
    color: IonColor = 'primary',
    header?: string
  ): Promise<HTMLIonToastElement> {
    return this.showToastWithOptions({
      message,
      duration,
      color,
      header,
    });
  }

  async showSuccessToast(
    message: string,
    duration = 2000,
    header?: string
  ): Promise<HTMLIonToastElement> {
    return this.showToast(message, duration, 'success', header);
  }

  async showErrorToast(
    message: string,
    duration = 3000,
    header?: string
  ): Promise<HTMLIonToastElement> {
    return this.showToast(message, duration, 'danger', header);
  }

  async showWarningToast(
    message: string,
    duration = 2000,
    header?: string
  ): Promise<HTMLIonToastElement> {
    return this.showToast(message, duration, 'warning', header);
  }
}
