import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  ViewChild,
} from '@angular/core';
import { IonLabel } from '@ionic/angular';
import { DefaultControlErrorComponent } from '@ngneat/error-tailor';

@Component({
  selector: 'hirit-control-error',
  templateUrl: './control-error.component.html',
  styleUrls: ['./control-error.component.scss'],
})
export class ControlErrorComponent extends DefaultControlErrorComponent {
  @HostBinding('class.hide-error')
  hideError!: boolean;
  @HostBinding('class.long-error')
  get longError(): boolean {
    return (this.label as any)?.el.offsetHeight > 24 || false;
  }
  @ViewChild('label')
  label!: IonLabel;

  constructor(
    host: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef, host);
  }
}
